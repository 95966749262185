import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { contentHolder, exampleSection, carouselImage } from "./services.module.css"
import { Row, Col, ListGroup, Carousel, Container } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const CommercialPage = ({ data, services }) => {
  return (
    <Layout>
      <Seo title="Commercial Services" />
      <div className={contentHolder}>
        <h2 className={"page-title"}>Commercial Services</h2>
        {/* <Row className={highlightRow}>
        <Col>
          <Image
            fluid
            src="http://placehold.jp/902x315.png"
          />
        </Col>
      </Row> */}
      <Row>
          <Col>
          <Container fluid>
            <Carousel>
              {data.allFile.edges.map(({ node }, i) => {
                return (
                  <Carousel.Item key={i}>
                    <GatsbyImage className={carouselImage} image={node.childImageSharp.gatsbyImageData} />
                  </Carousel.Item>)
              })}
            </Carousel>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className={contentHolder}>
            <p>From the tops of skyscrapers to the basements of military installations, Doctor Electric 
              is an experienced commerical electrical contractor. We have changed out light fixtures in parking decks, local schools and colleges, 
              wired restaurants and gas stations. Our team is ready to put together a plan to get your job done right. Need light fixtures? 
              We work with a local lighting distributor who can work with your budget and goals to make any lighting improvements or 
              install cost effective options.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className={exampleSection + " text-center"}>
            <h3>Customers</h3>
          </Col>
        </Row>
        <Row>
          <Col md={4} >
            <h4 className="text-center">High Rise Buildings</h4>
            <ListGroup variant="flush">
              <ListGroup.Item>Truist Tower - Tampa, FL</ListGroup.Item>
              <ListGroup.Item>Truist - Atlanta, GA</ListGroup.Item>
              <ListGroup.Item>PNC Plaze - Raleigh, NC</ListGroup.Item>
              <ListGroup.Item>Georgia Pacific - Atlanta, GA</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <h4 className="text-center">Government</h4>
            <ListGroup variant="flush">
              <ListGroup.Item>MCLB ALbany, GA</ListGroup.Item>
              <ListGroup.Item>Georgia World Congress Center</ListGroup.Item>
              <ListGroup.Item>Cobb County Schools</ListGroup.Item>
              <ListGroup.Item>Cowetta County Schools</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <h4 className="text-center">Others</h4>
            <ListGroup variant="flush">
              <ListGroup.Item>Rock'n Roll Sushi</ListGroup.Item>
              <ListGroup.Item>E. Sam Jones</ListGroup.Item>
              <ListGroup.Item>Bald Pates Foundation</ListGroup.Item>
              <ListGroup.Item>Stroud & Company</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>

      </div>
    </Layout>
  );
}

export default CommercialPage


export const CommercialPageQuery = graphql`query CommercialPageQuery {
  allFile(
    filter: {extension: {eq: "png"}, dir: {regex: "/highlights\\/commercial/"}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
}`
